'use client'

import Markdown from 'app/components/Markdown.client'
import './welcome-chat-bubble.scss'

interface IWelcomeChatBubbleProps {
  children: React.ReactNode
  className?: string
}

export default function WelcomeChatBubble({
  children,
  className,
}: IWelcomeChatBubbleProps) {
  if (!children) return null

  return (
    <div className={`welcomeChatBubble ${className ?? ''}`}>
      <div
        data-testid='chatbot-welcome-bubble'
        className='welcomeChatBubble__message'
      >
        {typeof children === 'string' ? (
          <Markdown>{children}</Markdown>
        ) : (
          children
        )}
      </div>
    </div>
  )
}
