/*
 * This file contains the schemas for the Snowplow events.
 */
export const schemas = {
  site: 'iglu:org.understood/site/jsonschema/1-0-8',
  content: 'iglu:org.understood/content/jsonschema/1-0-5',
  page: 'iglu:org.understood/page/jsonschema/1-0-0',
  engagement: 'iglu:org.understood/engagement/jsonschema/1-0-15',
  navigation: 'iglu:org.understood/navigation/jsonschema/1-0-12',
  partner: 'iglu:org.understood/partner/jsonschema/1-0-0',
}

export default schemas
