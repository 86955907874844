'use client'

import { Navigation, trackNavigation } from '@generated/snowplow'
import lowerCaseAndUnderscore from 'app/utils/lowerCaseAndUnderscore'
import getSharedContext, { trimPayload } from '../getSharedContext.client'
import { SharedContextEvent } from '../types'

type TrackNavigationClickProps = SharedContextEvent &
  Pick<Navigation, 'button_name' | 'button_text' | 'link_text'> & {
    click_type: 'button' | 'text'
  }

export default function trackNavigationClick(event: TrackNavigationClickProps) {
  if (
    process.env.NEXT_PUBLIC_SNOWPLOW_DISABLED === 'true' ||
    typeof window === 'undefined'
  ) {
    return
  }

  const navigationContext = trimPayload<Navigation>({
    event_type: 'click',
    button_name: event.button_name,
    click_type: event.click_type,
    button_text: event.button_text
      ? lowerCaseAndUnderscore(event.button_text)
      : event.button_text,
    link_text: event.link_text
      ? lowerCaseAndUnderscore(event.link_text)
      : event.link_text,
  })

  const sharedContext = getSharedContext({
    ...event,
    event_horizontal_position: window?.scrollX,
    event_vertical_position: window?.scrollY,
  })

  trackNavigation({
    ...navigationContext,
    ...sharedContext,
  })
}
