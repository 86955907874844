'use client'

import { Engagement, trackEngagement } from '@generated/snowplow'
import getSharedContext, { trimPayload } from '../getSharedContext.client'
import { SharedContextEvent } from '../types'

export type TrackEngagementClickProps = SharedContextEvent &
  Pick<Engagement, 'submitted_text' | 'submitted_object'>

export default function trackEngagementClick(event: TrackEngagementClickProps) {
  if (
    process.env.NEXT_PUBLIC_SNOWPLOW_DISABLED === 'true' ||
    typeof window === 'undefined'
  ) {
    return
  }

  const engagementContext = trimPayload<Engagement>({
    event_type: 'click',
    submitted_text: event.submitted_text,
    submitted_object: event.submitted_object
      ? JSON.stringify(event.submitted_object)
      : undefined,
  })

  const sharedContext = getSharedContext({
    ...event,
    event_horizontal_position: window?.scrollX,
    event_vertical_position: window?.scrollY,
  })

  trackEngagement({
    ...engagementContext,
    ...sharedContext,
  })
}
