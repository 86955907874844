'use client'

import { CommonEventProperties } from '@snowplow/browser-tracker'
import { Content, Page, Site, Partner } from '@generated/snowplow'
import { MODEL_NAME } from 'app/ai/constants'
import schemas from './schemas'
import { SharedContextEvent } from './types'

export function trimPayload<T extends Record<string, any>>(data: T): T {
  return Object.entries(data).reduce((prev, [k, v]) => {
    if (v === undefined || v === null) {
      return prev
    }

    return {
      ...prev,
      [k]: v,
    }
  }, {} as T)
}

export default function getSharedContext(
  event: SharedContextEvent
): CommonEventProperties<Site | Page | Content | Partner> {
  if (typeof window === 'undefined') {
    return {}
  }

  return {
    context: [
      {
        schema: schemas.site,
        data: trimPayload<Site>({
          unit_name: event.unit_name,
          unit_location: event.unit_location,
          unit_variation: event.unit_variation,
          event_horizontal_position: event.event_horizontal_position,
          event_vertical_position: event.event_vertical_position,
          linked_content_url: event.linked_content_url,
        }),
      },
      {
        schema: schemas.content,
        data: trimPayload<Content>({
          component_id: event.component_id,
        }),
      },
      {
        schema: schemas.page,
        data: trimPayload<Page>({
          site_section: 'chatbot',
        }),
      },
      {
        schema: schemas.partner,
        data: trimPayload<Partner>({
          partner_name: process.env.NEXT_PUBLIC_PARTNER_ID,
          trace_id: event.trace_id || event.component_id,
          model_version: MODEL_NAME,
        }),
      },
    ],
  }
}
