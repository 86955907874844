import(/* webpackMode: "eager", webpackExports: ["InternalAIProvider"] */ "/vercel/path0/node_modules/ai/rsc/dist/rsc-shared.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["AIProvider"] */ "/vercel/path0/src/app/components/AIProvider/AIProvider.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/ChatWindow.client/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackImpression"] */ "/vercel/path0/src/app/components/Snowplow/TrackImpression/TrackImpression.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Snowplow/TrackPageView.client/index.tsx");
