'use client'

import React from 'react'
import './popular-questions.scss'
import trackEngagementClick from '../Snowplow/lib/trackEngagementClick.client'

interface IPopularQuestionsProps {
  onSelect: (value: string) => void
  questions: string[]
}

export default function PopularQuestions({
  onSelect,
  questions,
}: IPopularQuestionsProps) {
  if (!questions?.length) return null

  return (
    <div className='popularQuestions'>
      <p className='popularQuestions__title'>Suggested:</p>
      <ul className='popularQuestions__list'>
        {questions.map((question) => (
          <li
            className='popularQuestions__item'
            key={question + Math.floor(Math.random() * 1000)}
          >
            <button
              type='button'
              data-testid='chatbot-suggestion-btn'
              className='popularQuestions__question'
              onClick={() => {
                onSelect(question)
                trackEngagementClick({
                  unit_name: 'conversation',
                  unit_location: 'inline',
                  submitted_object: 'suggested_query',
                })
              }}
            >
              {question}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
