'use client'

import './markdown.scss'
import React from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import { MarkdownTracker } from './types'

function MarkdownComponents({
  tracker,
}: {
  tracker?: MarkdownTracker
}): Components {
  return {
    a(anchorProps) {
      const { children, href, ...rest } = anchorProps

      return (
        <a
          {...rest}
          href={href}
          className='markdown__link'
          target='blank'
          rel='noopener'
          onClick={() => {
            if (
              typeof href === 'string' &&
              typeof children === 'string' &&
              tracker
            ) {
              tracker({ children, href })
            }
          }}
        >
          {children}
        </a>
      )
    },
  }
}

interface IMarkdownProps {
  children: string
  tracker?: MarkdownTracker
}

export default function Markdown({ children, tracker }: IMarkdownProps) {
  return (
    <ReactMarkdown
      className='markdown'
      components={MarkdownComponents({ tracker })}
    >
      {children}
    </ReactMarkdown>
  )
}
