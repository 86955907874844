'use client'

import { Navigation, Site, trackNavigation } from '@generated/snowplow'
import getSharedContext, { trimPayload } from '../getSharedContext.client'
import { SharedContextEvent } from '../types'

type TrackImpressionProps = SharedContextEvent &
  Pick<Site, 'event_type'> &
  Pick<Navigation, 'button_name'>

export default function trackImpression(event: TrackImpressionProps) {
  if (
    process.env.NEXT_PUBLIC_SNOWPLOW_DISABLED === 'true' ||
    typeof window === 'undefined'
  ) {
    return
  }

  const navigationContext = trimPayload<Navigation>({
    event_type: 'impression',
    button_name: event.button_name,
  })

  const sharedContext = getSharedContext(event)

  trackNavigation({
    ...navigationContext,
    ...sharedContext,
  })
}
