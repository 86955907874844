import { trackNavigation } from '@generated/snowplow'
import Markdown from '../Markdown.client'
import { TrackImpression } from '../Snowplow/TrackImpression/TrackImpression.client'
import './chat-modal.scss'

interface IChatModal {
  onClose: () => void
  modalTitle?: string
  modalContent?: string
  modalButton?: string
}

export default function ChatModal({
  onClose,
  modalTitle,
  modalContent,
  modalButton,
}: IChatModal) {
  const confirm = () => {
    trackNavigation({
      unit_name: 'disclaimer',
      unit_location: 'modal',
      button_name: 'confirm_disclaimer',
      click_type: 'button',
      event_type: 'click',
    })
    onClose()
  }

  return (
    <div className='chatModal' data-testid='chatbot-modal'>
      <TrackImpression unit_name='disclaimer' unit_location='modal' />
      {modalTitle && <h1 className='chatModal__title'>{modalTitle}</h1>}
      {modalContent && (
        <div className='chatModal__content'>
          <Markdown>{modalContent}</Markdown>
        </div>
      )}
      <div className='chatModal__cta'>
        <button data-testid='chatbot-modal-btn' type='button' onClick={confirm}>
          {modalButton}
        </button>
      </div>
    </div>
  )
}
