import { useRef, useEffect } from 'react'

export function useMutationObserver() {
  const messageViewRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const messageView = messageViewRef.current
    if (!messageView) return

    let timeout: NodeJS.Timeout

    const observer = new MutationObserver((mutationList) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const _mutation of mutationList) {
        const lastNode = messageView.lastElementChild
        if (!lastNode) return

        if (timeout) clearTimeout(timeout)

        timeout = setTimeout(() => {
          messageView.scrollTo({
            top:
              messageView.scrollTop + lastNode.getBoundingClientRect().top - 70,
            behavior: 'smooth',
          })
        }, 200)
      }
    })

    observer.observe(messageView, {
      childList: true,
      characterData: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  return messageViewRef
}
