import { trackSelfDescribingEvent, CommonEventProperties, SelfDescribingJson } from '@snowplow/browser-tracker';
// Automatically generated by Snowtype

/**
 * Schema for a web page context
 */
export type WebPage = {
    id: string;
}

/**
 * Schema for site context
 */
export type Site = {
    /**
     * what channel the page the event occurred on sits under
     */
    channel?: null | string;
    /**
     * how many pixels from the left an event occurs at
     */
    event_horizontal_position?: number | null;
    /**
     * how many pixels from the top an event occurs at
     */
    event_vertical_position?: number | null;
    /**
     * whether the event occurred on the english site or the spanish site
     */
    language?: null | string;
    /**
     * number of results shown for given search term
     */
    num_results?: number | null;
    /**
     * the position in unit the item is in, useful for carousel components
     */
    position_in_unit?: number | null | string;
    /**
     * the program to which an event corresponds
     */
    program?: null | string;
    /**
     * what search term was submitted for search results
     */
    search_term?: null | string;
    /**
     * the section of the site the event occurs on (e.g. channel, search, homepage)
     */
    site_section?: null | string;
    /**
     * what subtopic the page the event occurred on sits under
     */
    subtopic?: null | string;
    /**
     * what topic the page the event occurred on sits under
     */
    topic?: null | string;
    /**
     * where is the unit on the page
     */
    unit_location?: null | string;
    /**
     * what unit the event occurred on (breadcrumbs, tiny tout, etc.)
     */
    unit_name?: null | string;
    /**
     * The variation of the unit that is being displayed
     */
    unit_variation?: null | string;
    [property: string]: any;
}

/**
 * Schema for content context
 */
export type Content = {
    /**
     * If the component has child components, their contentful ids will be concatenated into a
     * list and placed here.
     */
    child_component_id?: any[] | null | string;
    /**
     * Contentful ID of the component that is being evented
     */
    component_id?: null | string;
    /**
     * the contentful id of a piece of content
     */
    content_id?: null | string;
    /**
     * whether the article views is a q&a article or a standard article
     */
    content_type?: null | string;
    /**
     * Matching value in Contentful for above content_id
     */
    content_type_id?: null | string;
    /**
     * the id of the external content if possible (ex. YT or vimeo)
     */
    external_content_id?: null | string;
    /**
     * the horizontal position of the unit an event occurred on
     */
    horizontal_position?: number | null;
    /**
     * the name of the model powering a recommendation seen/clicked on
     */
    model_name?: null | string;
    /**
     * the version number of the model powering a recommendation seen/clicked on
     */
    model_version?: null | string;
    /**
     * If the component has a parent, the contentful id of that parent component.
     */
    parent_component_id?: null | string;
    /**
     * If the component has a parent with multiple children, the numeric order that the
     * component being evented appears within the parent
     */
    position_in_parent_component?: number | null;
    /**
     * the vertical position of the unit an event occurred on
     */
    vertical_position?: number | null;
}

/**
 * Schema for page context
 */
export type Page = {
    /**
     * The contentful_id of the parent page/article that is loading content
     */
    contentful_id?: null | string;
    /**
     * SGP: contentful_id for the component of the header?
     */
    header_id?: null | string;
    /**
     * This is True if the page is in Spanish
     */
    is_spanish?: boolean | null;
    /**
     * Asset name for page/article in contentful
     */
    page_name?: null | string;
    /**
     * Slug name of page
     */
    page_slug?: null | string;
    /**
     * Title value for page/article in Contentful
     */
    page_title?: null | string;
    /**
     * Name of section of the site that the page/article lives in.
     */
    site_section?: null | string;
    [property: string]: any;
}

/**
 * Schema for events related to engagement for site and wunder app
 */
export type Engagement = {
    /**
     * The 'I am' conditional selections when someones saves there account preferences
     */
    audience?: { [key: string]: any } | null | string;
    /**
     * the name of the button clicked on in the engagement - should match the impression
     */
    button_name?: null | string;
    /**
     * id generated in the backend for a comment on a post
     */
    comment_id?: any[] | null | string;
    /**
     * contentful id for an article
     */
    content_id?: any[] | null | string;
    /**
     * title of the download clicked on
     */
    download_title?: null | string;
    /**
     * type of engagement
     */
    event_type: string;
    /**
     * id generated in the backend for a group
     */
    group_id?: any[] | null | string;
    /**
     * if event_type is rating, this should be whether the user said yes or no
     */
    helpful?: null | string;
    /**
     * id of the video being viewed for video-related events
     */
    interval?: number | null;
    /**
     * contentful id of content clicked on/seen
     */
    linked_content_id?: null | string;
    /**
     * url of content clicked on/seen
     */
    linked_content_url?: null | string;
    /**
     * id generated in the backend for a microjourney
     */
    microjourney_id?: any[] | null | string;
    /**
     * Percent of video played, this fires when 5, 25, 50, 75, and 100% of the video plays
     */
    percent_completed?: number | null;
    /**
     * if the event_type is share_complete or follow, what platform (facebook, twitter, etc.)
     */
    platform?: null | string;
    /**
     * id generated in the backend for a post in a group
     */
    post_id?: any[] | null | string;
    /**
     * the program a user signed up for when subscribing to a newsletter
     */
    program?: any[] | null | string;
    /**
     * raction id
     */
    reaction_id?: any[] | null | string;
    /**
     * when a user reacts to a post, this will tell us what kind of reaction (like, heart, etc.)
     * we haven't determined the different types yet, so we'll need to fill these in once those
     * are finalized
     */
    reaction_type?: any[] | null | string;
    /**
     * id generated in the backend for a reply to a comment
     */
    reply_id?: any[] | null | string;
    /**
     * resource id
     */
    resource_id?: any[] | null | string;
    /**
     * whether form submission was successful or not
     */
    submit_success?: boolean | null;
    /**
     * Used for lists, such as list of topic ids when someone selects topics from the topic
     * selector modal or account preferences
     */
    submitted_list?: any[] | null | string;
    /**
     * Used whenever we are submitting a dictionary of data, ex. madlibs
     */
    submitted_object?: { [key: string]: any } | null | string;
    /**
     * text submitted for search events (and possibly other text field event types in the future)
     */
    submitted_text?: any[] | null | string;
    /**
     * id of the video being viewed for video-related events
     */
    video_id?: null | string;
    [property: string]: any;
}

/**
 * Schema for all events related to navigation for the site and wunder app
 */
export type Navigation = {
    /**
     * the name of the button clicked on/seen
     */
    button_name?: null | string;
    /**
     * Text contained within button. Null if click_type = text.
     */
    button_text?: null | string;
    /**
     * the type of button clicked on/seen
     */
    button_type?: null | string;
    /**
     * id generated in the backend for a comment on a post
     */
    comment_id?: any[] | null | string;
    /**
     * contentful id for an article
     */
    content_id?: any[] | null | string;
    /**
     * whether the user saw a feature or clicked on it
     */
    event_type: EventType;
    /**
     * id generated in the backend for a group
     */
    group_id?: any[] | null | string;
    /**
     * denotes whether the item being clicked on/seen is a piece of Understood content
     */
    is_content?: boolean | null;
    /**
     * whether the item being clicked on/seeen is an external link
     */
    is_external?: boolean | null;
    /**
     * True if link causes a modal pop-up
     */
    is_modal?: boolean | null;
    /**
     * Text for hyperlink. Null if click_type = button
     */
    link_text?: null | string;
    /**
     * contentful id of content clicked on/seen
     */
    linked_content_id?: null | string;
    /**
     * url of content clicked on/seen
     */
    linked_content_url?: null | string;
    /**
     * id generated in the backend for a microjourney
     */
    microjourney_id?: any[] | null | string;
    /**
     * position index of item in unit
     */
    position_in_unit?: null | string;
    /**
     * id generated in the backend for a post in a group
     */
    post_id?: any[] | null | string;
    /**
     * raction id
     */
    reaction_id?: any[] | null | string;
    /**
     * when a user reacts to a post, this will tell us what kind of reaction (like, heart, etc.)
     * we haven't determined the different types yet, so we'll need to fill these in once those
     * are finalized
     */
    reaction_type?: any[] | null | string;
    /**
     * id generated in the backend for a reply to a comment
     */
    reply_id?: any[] | null | string;
    /**
     * resource id
     */
    resource_id?: any[] | null | string;
    /**
     * Used for lists, such as list of topic ids when someone selects topics from the topic
     * selector modal or account preferences
     */
    submitted_list?: any[] | null | string;
    /**
     * Used whenever we are submitting a dictionary of data, ex. madlibs
     */
    submitted_object?: { [key: string]: any } | null | string;
    [property: string]: any;
}

/**
 * whether the user saw a feature or clicked on it
 */
export type EventType = "impression" | "click" | "model_loaded";

/**
 * Schema for partner
 */
export type Partner = {
    /**
     * model version running on a particular embed
     */
    model_version?: null | string;
    /**
     * the id or name of the partner
     */
    partner_id?: null | string;
    /**
     * id that will match back to LangSmith
     */
    trace_id?: null | string;
    [property: string]: any;
}


type ContextsOrTimestamp<T = any> = Omit<CommonEventProperties<T>, 'context'> & { context?: SelfDescribingJson<T>[] | null | undefined }
/**
 * Track a Snowplow event for WebPage.
 * Schema for a web page context
 */
export function trackWebPage<T extends {} = any>(webPage: WebPage & ContextsOrTimestamp<T>, trackers?: string[]){
    const { context, timestamp, ...data } = webPage;
    const event: SelfDescribingJson = {
        schema: 'iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow WebPage entity.
 */
export function createWebPage(webPage: WebPage){
    return {
        schema: 'iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0',
        data: webPage
    }
}
/**
 * Track a Snowplow event for Site.
 * Schema for site context
 */
export function trackSite<T extends {} = any>(site: Site & ContextsOrTimestamp<T>, trackers?: string[]){
    const { context, timestamp, ...data } = site;
    const event: SelfDescribingJson = {
        schema: 'iglu:org.understood/site/jsonschema/1-0-8',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Site entity.
 */
export function createSite(site: Site){
    return {
        schema: 'iglu:org.understood/site/jsonschema/1-0-8',
        data: site
    }
}
/**
 * Track a Snowplow event for Content.
 * Schema for content context
 */
export function trackContent<T extends {} = any>(content: Content & ContextsOrTimestamp<T>, trackers?: string[]){
    const { context, timestamp, ...data } = content;
    const event: SelfDescribingJson = {
        schema: 'iglu:org.understood/content/jsonschema/1-0-5',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Content entity.
 */
export function createContent(content: Content){
    return {
        schema: 'iglu:org.understood/content/jsonschema/1-0-5',
        data: content
    }
}
/**
 * Track a Snowplow event for Page.
 * Schema for page context
 */
export function trackPage<T extends {} = any>(page: Page & ContextsOrTimestamp<T>, trackers?: string[]){
    const { context, timestamp, ...data } = page;
    const event: SelfDescribingJson = {
        schema: 'iglu:org.understood/page/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Page entity.
 */
export function createPage(page: Page){
    return {
        schema: 'iglu:org.understood/page/jsonschema/1-0-0',
        data: page
    }
}
/**
 * Track a Snowplow event for Engagement.
 * Schema for events related to engagement for site and wunder app
 */
export function trackEngagement<T extends {} = any>(engagement: Engagement & ContextsOrTimestamp<T>, trackers?: string[]){
    const { context, timestamp, ...data } = engagement;
    const event: SelfDescribingJson = {
        schema: 'iglu:org.understood/engagement/jsonschema/1-0-15',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Engagement entity.
 */
export function createEngagement(engagement: Engagement){
    return {
        schema: 'iglu:org.understood/engagement/jsonschema/1-0-15',
        data: engagement
    }
}
/**
 * Track a Snowplow event for Navigation.
 * Schema for all events related to navigation for the site and wunder app
 */
export function trackNavigation<T extends {} = any>(navigation: Navigation & ContextsOrTimestamp<T>, trackers?: string[]){
    const { context, timestamp, ...data } = navigation;
    const event: SelfDescribingJson = {
        schema: 'iglu:org.understood/navigation/jsonschema/1-0-12',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Navigation entity.
 */
export function createNavigation(navigation: Navigation){
    return {
        schema: 'iglu:org.understood/navigation/jsonschema/1-0-12',
        data: navigation
    }
}
/**
 * Track a Snowplow event for Partner.
 * Schema for partner
 */
export function trackPartner<T extends {} = any>(partner: Partner & ContextsOrTimestamp<T>, trackers?: string[]){
    const { context, timestamp, ...data } = partner;
    const event: SelfDescribingJson = {
        schema: 'iglu:org.understood/partner/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Partner entity.
 */
export function createPartner(partner: Partner){
    return {
        schema: 'iglu:org.understood/partner/jsonschema/1-0-0',
        data: partner
    }
}



