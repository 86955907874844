export default function lowerCaseAndUnderscore(str: string): string {
  if (!str) {
    return str
  }
  return str
    .toLowerCase()
    .replace(/-/g, '_')
    .replace(/[.,&;()]/g, '')
    .replace(/ /g, '_')
    .replace(/__/g, '_')
}
