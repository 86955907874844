'use client'

import Markdown from '../Markdown.client'
import './user-chat-bubble.scss'

interface IUserChatBubbleProps {
  children: React.ReactNode
  className?: string
}

export default function UserChatBubble({
  children,
  className,
}: IUserChatBubbleProps) {
  return (
    <div
      className={`userChatBubble ${className ?? ''}`}
      data-testid='chatbot-user-msg'
    >
      <div className='userChatBubble__message'>
        {typeof children === 'string' ? (
          <Markdown>{children}</Markdown>
        ) : (
          children
        )}
      </div>
      <span className='userChatBubble__avatar'>You</span>
    </div>
  )
}
